// export default App;
import { BrowserRouter, Routes, Route } from "react-router-dom";
import PrivacyScreen from "./pages/PrivacyScreen";
import RemoveAccount from "./pages/RemoveAccount";
import SupportScreen from "./pages/SuportPage";
import ATest from "./pages/A_test";
import BTest from "./pages/B_test";

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route index element={<ATest />} />
        <Route path="/qghwuad9-8qy9-148y2huwndqB" element={<BTest />} />
        <Route path="/privacy" element={<PrivacyScreen />} />
        <Route path="/remove" element={<RemoveAccount />} />
        <Route path="/support" element={<SupportScreen />} />
      </Routes>
    </BrowserRouter>
  );
}
