import React from "react";

function SupportScreen() {
  return (
    <div className="bg-gradient-to-t from-light-blue to-27% to-deep-blue h-screen w-full z-10 ">
      <div className="mx-20 py-20">
        <h1 className="text-white text-left text-4xl font-bold tracking-widest ">
          Suporte
        </h1>
        <div className="bg-white py-5 rounded-xl px-5 w-70 max-w-md">
          <div className="flex-row">
            <div>
              <h2>
                Para entrar em contato mande uma mensagem para o nosso whatsApp
              </h2>
              <h3 className="font-semibold">+55 (32) 9 9120-8205</h3>
            </div>
            <div className="mt-12 flex-wrap">
              <h2>Ou nos mande um e-mail</h2>
              <h3 className="font-semibold overflow-clip">
                contato.faltometro@gmail.com
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SupportScreen;
