import { createTheme, ThemeProvider } from "@mui/material";
import "../assets/styles/A_test.css";
import MainMock from "../assets/images/4.svg";
import smallerSecondMock from "../assets/images/smallerSecondMock.svg";

import PriceCard from "../components/PriceCard";

import { logEvent } from "firebase/analytics";
import { analytics } from "../firebase-config";
import FunctionalitySection from "../components/FunctionalitySection";
import LinksSection from "../components/LinksSection";
import PricingSection from "../components/PricingSection";

export const theme = createTheme({
  typography: {
    fontFamily: "Poppins",
  },
});

export default function ATest() {
  const scrollToDownloadSection = () => {
    const downloadSection = document.getElementById("download-section");
    downloadSection.scrollIntoView({ behavior: "smooth" });
    logEvent(analytics, "clicked-first-CTA-A");
  };

  return (
    <div className="container">
      <section className="slides" id="first-section">
        <div>
          <h1>Sempre saiba quantas faltas você tem na faculdade</h1>
        </div>

        <div>
          <button className="main-cta-button" onClick={scrollToDownloadSection}>
            BAIXE AGORA
          </button>
        </div>
        <div className="main-mock">
          <img src={MainMock} alt="Iphone with app opened" />
        </div>
      </section>
      <div className="spacer"></div>

      <section className="slides" id="second-section">
        <div className="flex flex-col justify-between items-center py-12 gap-5">
          <div className="second-section-title">
            <h1>A melhor forma de monitorar suas faltas</h1>
          </div>
          <div className="text-block ">
            <p>
              Faça login, cadastre a matéria, quantas faltas pode ter e pronto!
              Ao fim da aula uma notificação te lembrará de marcar se faltou ou
              não
            </p>
          </div>
        </div>

        <div className="second-mock">
          <img
            src={smallerSecondMock}
            alt="Home screen of iPhone wiht notification"
          />
        </div>
      </section>
      <div className="spacer-reverse"></div>
      <FunctionalitySection />
      {/* <section className="slides" id="pricing-section">
        <h1 className="pricing-section-title">Disponível em três versões</h1>
        <div className="pricing-card-section">
          <PriceCard planName={"Basic"} planPrice={"Gratuito"} />
          <PriceCard planName={"Premium"} planPrice={"4,99"} />
          <PriceCard
            planName={"Premium"}
            planPrice={"12,99"}
            quarterly={true}
          />
        </div>
      </section> */}
      {/* <section className="flex flex-col justify-center items-center bg-[#004aad] pt-12">
        <PricingSection group={"A"} />
      </section> */}
      <LinksSection group={"A"} />
    </div>
  );
}
