import React, { useState } from "react";
import FormEntry from "../components/FormEntry";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import { db } from "../firebase-config";
import ok from "../assets/images/okCheck.svg";

function RemoveAccount() {
  const [email, setEmail] = useState(" ");
  const [reason, setReason] = useState(" ");
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [sent, setSent] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (email.includes("@") || email.includes(".com")) {
      await addDoc(collection(db, "remove-account"), {
        email,
        reason,
        createdAt: serverTimestamp(),
      });
      setSent(true);
    } else {
      setInvalidEmail(true);
    }
  };

  return (
    <div className="bg-gradient-to-t from-light-blue to-27% to-deep-blue h-screen w-full flex-col justify-center  ">
      <div className="mx-auto py-20">
        <h1 className="text-white text-center text-2xl font-bold tracking-widest ">
          {sent ? null : "Formulário para remoção de conta"}
        </h1>
      </div>
      <div className="bg-white py-5 rounded-xl px-2 w-80 mx-auto">
        {sent ? (
          <div>
            <div>
              <img className="mx-auto mb-3" src={ok} alt="" />
              <h1 className="text-black text-center text-2xl font-bold leading-6 mx-8">
                Pedido enviado
              </h1>
            </div>
            <div className="mt-10">
              <h3 className="text-center text-xl font-medium">
                Dentro de alguns dias você receberá um e-mail de confirmação
                para finalizar o processo.
              </h3>
            </div>
          </div>
        ) : (
          <>
            <div>
              <div className="my-6">
                <FormEntry
                  title={"E-mail"}
                  placeholder={"E-mail"}
                  variavel={email}
                  setStatus={(e) => setEmail(e.target.value)}
                />
                {invalidEmail && (
                  <p className="text-center text-red-500 font-medium mt-2">
                    Favor verificar o e-mail
                  </p>
                )}
              </div>

              <FormEntry
                title={"Motivo"}
                placeholder={"Conte nos porque tomou essa decisão"}
                variavel={reason}
                setStatus={(e) => setReason(e.target.value)}
              />
            </div>
            <div className="w-full flex justify-center">
              <button
                onClick={(e) => handleSubmit(e)}
                className="bg-deep-blue w-5/6 mt-10 py-4 rounded-2xl text-white font-semibold tracking-wideller"
              >
                ENVIAR
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default RemoveAccount;
