import React from "react";
import googleBagde from "../assets/images/google-play-badge.png";
import appleBadge from "../assets/images/appleStoreBadge.svg";
import faltometroLogo from "../assets/images/faltometroFooterLogo.svg";
import faltometroInstagram from "../assets/images/faltometroInsta.svg";
import { logEvent } from "firebase/analytics";
import { analytics } from "../firebase-config";

function LinksSection({ group }) {
  return (
    <section className="slides" id="fourth-section">
      <div
        className="flex flex-col justify-center items-center my-40"
        id="download-section"
      >
        <a
          onClick={() => logEvent(analytics, `download-google-${group}`)}
          href="https://play.google.com/store/apps/details?id=com.hugo_pinho.FaltometroMobile"
        >
          <img
            className="w-80 hover:cursor-pointer"
            src={googleBagde}
            alt="Google playstore badge"
          />
        </a>
        <a
          onClick={() => logEvent(analytics, `download-apple-${group}`)}
          className="-mb-5 pt-6"
          href="https://apps.apple.com/br/app/faltometro/id6461687940"
          id="apple-download-button"
        >
          <img className="w-80" src={appleBadge} alt="Apple Store badge" />
        </a>
      </div>
      <div className="pt-28 mb-8 w-full mx-auto">
        <img className="mx-auto" src={faltometroLogo} alt="Faltometro logo" />
        <img
          className="mx-auto mt-5"
          src={faltometroInstagram}
          alt="Instagram logo"
        />
      </div>
      <p className="text-sm text-center mx-5  text-black">
        Google Play e o logotipo do Google Play são marcas registradas da Google
        LLC.
      </p>
      <div className="bg-white py-5 w-full">
        <p className="text-lg text-center font-medium text-black">
          Feito por Hugo Pinho
        </p>
      </div>
    </section>
  );
}

export default LinksSection;
