// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAmgG-80u4c86TQmANhrsSgPUY5Gd1ruqA",
  authDomain: "faltometro-web.firebaseapp.com",
  projectId: "faltometro-web",
  storageBucket: "faltometro-web.appspot.com",
  messagingSenderId: "501063261655",
  appId: "1:501063261655:web:7faee8263139c43e353f92",
  measurementId: "G-QVF84ENMSS",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const db = getFirestore(app);
