import React from "react";
import "../assets/styles/FunctionalitySection.css";
import subjectBadge from "../assets/images/subjectsBadge.svg";
import notificationBadge from "../assets/images/notificationBadge.svg";
import dashboardBadge from "../assets/images/dashboardBadge.svg";

function FunctionalitySection() {
  return (
    <section className="slides" id="third-section">
      <div className="third-section-title">
        <h1>Funcionalidades</h1>
      </div>
      <div className="functionality-section">
        <div className="single-functionality">
          <img src={subjectBadge} alt="books illustration" />
          <h3 className="functionality-text">
            Cadastre quantas ausências quiser
          </h3>
        </div>
        <div className="single-functionality ">
          <img src={notificationBadge} alt="bell illustration" />
          <h3 className="functionality-text">
            Seja notificado quando acabar a aula
          </h3>
        </div>
        <div className="single-functionality">
          <img src={dashboardBadge} alt="Velocimeter illustration" />
          <h3 className="functionality-text">
            Sempre saiba quantas faltas você pode ter
          </h3>
        </div>
      </div>
    </section>
  );
}

export default FunctionalitySection;
