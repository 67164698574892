import React, { useState } from "react";

function FormEntry({ title, placeholder, setStatus, variavel }) {
  return (
    <div className="flex-col w-5/6 mx-auto my-5">
      <p className="font-medium text-deeper-blue">{title}</p>
      <input
        type={title === "Senha" ? "password" : null}
        value={variavel}
        onChange={setStatus}
        className="border-deep-blue border-2 py-4 rounded-xl w-full placeholder:text-left pl-2 text-clip"
        placeholder={placeholder}
      />
    </div>
  );
}

export default FormEntry;
